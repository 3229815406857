var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "450", height: "300", zIndex: "999999" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Edit Priority"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [
                _vm._v(
                  "Priority (500 or over will show recommended tag on results)"
                ),
              ]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min_value:0|max_value:999",
                    expression: "'required|min_value:0|max_value:999'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  type: "number",
                  min: "0",
                  max: "999",
                  name: "Priority",
                },
                model: {
                  value: _vm.priority,
                  callback: function ($$v) {
                    _vm.priority = _vm._n($$v)
                  },
                  expression: "priority",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Priority"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-4" },
            [
              _c("label", {}, [
                _vm._v(
                  "Optional Feature Tags (comma-delimited list of feature tags)"
                ),
              ]),
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:800",
                    expression: "'max:800'",
                  },
                ],
                staticClass: "w-full mb-0",
                attrs: {
                  name: "Optional Feature Tags",
                  rows: "6",
                  counter: "800",
                  maxlength: "800",
                },
                model: {
                  value: _vm.features,
                  callback: function ($$v) {
                    _vm.features = $$v
                  },
                  expression: "features",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Optional Features"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.onCancel },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.onUpdate },
            },
            [_vm._v("Update")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }