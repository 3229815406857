<template>
  <travio-center-container grid-width='full'>
    <h2 style="color:#636363">Accommodation Overrides</h2>
    <p v-html="infoLink" class="mt-2 mb-4"></p>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-1/3">
          <label class="text-sm">Hotel Name Search</label>
          <vs-input v-model="searchText" name="Search" class="w-full" v-validate="'required|min:5|max:250'"></vs-input>
          <span class="text-danger text-sm">{{ errors.first('Search') }}</span>
        </div>

        <div class="vx-col w-2/3">
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <label class="text-sm">Supplier</label>
              <v-select
                  v-model="supplierId"
                  name="Supplier"
                  :clearable="false"
                  :reduce="(x) => x.code"
                  :options="supplierOptions"
                />
            </div>
            <div class="vx-col w-2/3">
               <div class="vx-row">
                  <div class="vx-col w-1/3">
                    <label class="text-sm">Minimum Star Rating</label>
                    <v-select
                      v-model="minRating"
                      name="Minimum Star Rating"
                      :clearable="false"
                      :reduce="(x) => x.code"
                      :options="ratingOptions"
                    />
                  </div>
                  <div class="vx-col w-1/3">
                    <label class="text-sm">Country Code <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2" target="_blank">ISO codes</a></label>
                    <vs-input v-model="countryCode" name="Country Code" v-validate="'min:2'" maxLength="2" class="w-full" />
                    <span class="text-danger text-sm">{{ errors.first('Country Code') }}</span>
                  </div>
                  <div class="vx-col w-1/3">
                    <label class="text-sm">Number of Results</label>
                    <v-select
                      v-model="numberOfResults"
                      name="Number of Results"
                      :clearable="false"
                      :reduce="(x) => x.code"
                      :options="resultOptions"
                    />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="vx-row">
        <div class="vx-col w-full mt-4">
          <vs-button @click="search" class="mr-4 sm:mb-0 mb-2">Search</vs-button>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="accommodationList"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="true"
        :context="context"
        :suppressPaginationPanel="false"
      >
      </ag-grid-vue>
      
      <div class="vx-row">
        <div class="vx-col w-full mt-4">
          <vs-button @click="updateProperties" :disabled="disableUpdateRequest" class="mr-4 sm:mb-0 mb-2">Update Properties</vs-button>
        </div>
      </div>
    </vx-card>
    <accomodation-override-editor
      v-if="showAccomodationEditor"
      :data="accomdationForUpdate"
      @onUpdate="handleUpdateEditor"
      @onCancel="showAccomodationEditor=false"
    />
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererAccommodationActiveCheckbox from './cell-renderers/CellRendererAccommodationActiveCheckbox.vue'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import CellRendererUniqueRef from './cell-renderers/CellRendererUniqueRef.vue'
import AccomodationOverrideEditor from './components/AccomodationOverrideEditor.vue'

export default {
  components: {
    AgGridVue,
    CellRendererAccommodationActiveCheckbox,
    CellRendererActions,
    CellRendererUniqueRef,
    AccomodationOverrideEditor
  },
  props: {
    applicationId: { required: true },
  },
  data () {
    return {
      accommodationList: [],
      accommodationListForUpdate: [],
      searchRequest: null,
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererAccommodationActiveCheckbox,
        CellRendererActions
      },
      supplierOptions: [],
      ratingOptions: [],
      resultOptions: [],
      searchText: '',
      supplierId: "0",
      minRating: "0",
      countryCode: null,
      numberOfResults: 50,
      context: null,
      accomdationForUpdate: {},
      showAccomodationEditor: false,
      infoLink:'Enable/disable properties from your enabled suppliers and override some of the information. If you want to exclude a property from your search results, you can disable them using this tool. You can also prioritise results to appear at the top of your results by increasing the priority field to a value greater than 0 (the greater the number, the higher up the results list it displays). By setting the priority to 500 or more, it will also display the recommended property flag in your results.'
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    disableUpdateRequest () {
      return !this.accommodationListForUpdate || this.accommodationListForUpdate.length === 0
    }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    
    this.$vs.loading()
    try {
      const response = await this.$http.get(`api/accomomodationOverrides/apps/${this.applicationId}/formdata`)
      // using integer code for v-select doesn't work well with 0 as the value so convert id to string
      this.supplierOptions = response.data.suppliers.map(x => ({ code: x.id.toString(), label: x.name }))
      this.supplierOptions.unshift({ code: "0", label: "Any" })
    }
    catch (error) {
      this.$_notifyFailure(error)
    }
    this.$vs.loading.close()

    this.resultOptions  = [
      { code : 25, label: "25"},
      { code : 50, label: "50" },
      { code : 75, label: "75" },
      { code : 100, label: "100" },
    ]
    
    this.ratingOptions  = [
      { code : "0", label: "Any" },
      { code : "1", label: "1*" },
      { code : "2", label: "2*" },
      { code : "3", label: "3*" },
      { code : "4", label: "4*" },
      { code : "5", label: "5*" }
    ]

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', width: 120, hide: true },
      { headerName: 'Supplier', field: 'supplier', width: 150,  suppressSizeToFit: false },
      { headerName: 'Name', field: 'name', width: 300,  suppressSizeToFit: false },
      { headerName: 'Country', field: 'country', width: 120,  suppressSizeToFit: true },
      { headerName: 'Rating', width: 100,  suppressSizeToFit: true, valueGetter: params => this.ratingFormatter(params.data.rating) },
      { field: 'uniqueRef', hide: true },
      { headerName: 'Unique Ref', width: 150,  suppressSizeToFit: false, cellRendererFramework: Vue.extend(CellRendererUniqueRef) },
      { headerName: 'Priority', field: 'priority', width: 120,  suppressSizeToFit: false },
      { headerName: 'Enabled', field: 'enabled', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAccommodationActiveCheckbox) },
      { headerName: 'Features', field: 'features', hide: true },
      { headerName: 'Actions', field: 'enabled', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    ratingFormatter(rating) {
      return ( rating && (Math.round(rating * 100) / 100).toFixed(1) ) || ''
    },
    async search () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      this.searchRequest = {
        search: this.searchText,
        supplierId: parseInt(this.supplierId),
        minRating: parseInt(this.minRating),
        countryCode: this.countryCode,
        maxResults: this.numberOfResults
      }

      this.$vs.loading()
      try {
        const response = await this.$http.post(`api/accomomodationOverrides/apps/${this.applicationId}/search`, this.searchRequest)
        this.accommodationList = response.data.accomodations.map(x => ({
          id: x.id,
          supplier: x.supplier,
          supplierRef: x.supplierRef,
          name: x.name,
          rating: x.rating,
          country: x.countryCode,
          priority: x.priority,
          enabled: x.enabled,
          uniqueRef: x.uniqueRef,
          features: x.features
        }))
        this.accommodationListForUpdate = []
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    enableAccommodation (id) {
      const changedItem = this.accommodationList.find(x => x.id === id);
      this.accommodationListForUpdate = this.accommodationListForUpdate.filter(x => x.id !== id)
      this.accommodationListForUpdate.push(changedItem)
    },
    async updateProperties () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      // Use the original search request, user can update the search fields making it out of sync with the request
      const updateRequest = Object.assign(this.searchRequest, { 
        properties: this.accommodationListForUpdate.map(x => ({
          id: x.id,
          priority: x.priority,
          enabled: x.enabled,
          features: x.features
        }))
      })

      this.$vs.loading()
      try {
        const response = await this.$http.post(`api/accomomodationOverrides/apps/${this.applicationId}/update`, updateRequest)
        this.accommodationListForUpdate = []
        this.$_notifySuccess('Successfully updated properties.');
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    launchEditor(data) {
      this.accomdationForUpdate = JSON.parse(JSON.stringify(data))
      this.showAccomodationEditor=true
    },
    handleUpdateEditor (data) {
      const changedItem = this.accommodationList.find(x => x.id === data.id);
      changedItem.priority = data.priority
      changedItem.features = data.features
      this.accommodationList = [...this.accommodationList]

      this.accommodationListForUpdate = this.accommodationListForUpdate.filter(x => x.id !== data.id)
      this.accommodationListForUpdate.push(changedItem)
      this.showAccomodationEditor=false
    }

  }
}
</script>

<style>

</style>
